import { clientUrl } from "@/constants"
import { useAuth } from "@/providers/authContext"
import { useReferralStore } from "@/stores"
import { useMemo } from "react"
import QRCode from "react-qr-code"
import Modal from "../Modal"
import TextCopy from "../TextCopy"
import { SocialAlphaButtons } from "./ShareSocialModal"
import TitleBorderBothSides from "../TitleBorderBothSides"

const ReferralModalV2 = () => {
  const { userInfoQuery } = useAuth()

  const { referralModal, setReferralModal } = useReferralStore()

  const inviteLink = useMemo(
    () => `${clientUrl}/workspace?referralCode=${userInfoQuery?.data?.refCode}`,
    [userInfoQuery?.data?.refCode],
  )

  return (
    <Modal
      closeButtonClassName="absolute top-4 right-4"
      bodyClassName="p-0"
      className="max-w-sm overflow-visible p-0 bg-transparent"
      isOpen={referralModal}
      onClose={() => setReferralModal(false)}
    >
      <div
        className="flex-1 flex flex-col p-[2px] rounded-[32px] overflow-hidden"
        style={{
          background: "linear-gradient(180deg, #272727 0%, #555555 100%)",
        }}
      >
        <div
          className="flex-1 rounded-[30px] flex flex-col p-4"
          style={{
            background: "linear-gradient(180deg, #000000 0%, #393939 100%)",
          }}
        >
          <div className="flex-1 space-y-4">
            <div className="px-4">
              <TitleBorderBothSides className="mb-2">SCAN QR CODE</TitleBorderBothSides>
              <div className="h-[20rem] w-full bg-white p-4 rounded-3xl flex items-center justify-center">
                <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={inviteLink}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div>
            <div className="px-4">
              <TitleBorderBothSides className="mb-2">INVITE LINK</TitleBorderBothSides>
              <div className="border-[2px] border-atherGray-500 rounded-full p-2 flex items-center overflow-hidden">
                <p className="truncate mr-4 text-atherGray-300 flex-1">{inviteLink}</p>
                <TextCopy width={20} height={20} value={inviteLink} />
              </div>
            </div>

            <div>
              <TitleBorderBothSides className="mb-2">SOCIAL</TitleBorderBothSides>
              <div className="flex space-x-4 justify-center">
                <SocialAlphaButtons mutateShareSocial={() => {}} urlImageDetails={inviteLink} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ReferralModalV2
