import { cn } from "@/lib/utils"
import React from "react"

interface TitleBorderBothSidesProps {
  children: React.ReactNode
  className?: string
  childrenClassName?: string
}

const TitleBorderBothSides = ({ children, className, childrenClassName }: TitleBorderBothSidesProps) => {
  return (
    <div className={cn("flex items-center", className)}>
      <div
        className="flex-1 h-[2px]"
        style={{
          background: "linear-gradient(90deg, rgba(76, 75, 71, 0) 0%, #4C4B47 100%)",
        }}
      />
      <span className={cn("font-semibold text-xs text-atherGray-300 px-2", childrenClassName)}>{children}</span>
      <div
        className="flex-1 h-[2px]"
        style={{
          background: "linear-gradient(90deg, #4C4B47 0%, rgba(76, 75, 71, 0) 100%)",
        }}
      />
    </div>
  )
}

export default TitleBorderBothSides
